﻿$primary-nav-background: '/assets/images/FWS/FWSHeaderBack.svg';
$secondary-nav-background: "/assets/images/FWS/FWSHeaderBack2.svg";
$small-nav-background: "/assets/images/FWS/FWSHeaderBack2sm.svg";
$mobile-nav-background: "/assets/images/FWS/mobilewaterheader.svg";

.brand-logo {
    svg {
        width: 200px;

        @include media-breakpoint-down(sm) {
            width: 120px;
        }
    }
}
.mobile-brand {
    img {
        float: right;
        width: 200px;

        @include media-breakpoint-down(md) {
            margin-right: 1rem;
            margin-top: .75rem;
        }

        @include media-breakpoint-down(sm) {
            width: 100px;
            margin-right: .5rem;
        }
    }
}
.tiptop-navbar {
    .sitewide-call-to-action {

        .fe-btn {
            padding: 2px 12px 3px 12px;

            svg {
                display: none;
            }

            span.btn-text {
                padding-right: 0px !important;
            }
        }

        .ffs-green {
            background: none;
            background-color: #3d3d3d;
            border: none;
        }
    }

    ul.utility-navigation.with-button {
        margin-top: 0px !important;
        margin-right: .5rem;
    }
}
