﻿table {
    &.table-bordered {
        border: none !important
    }

    &.table {
        &.table-striped tbody tr:nth-of-type(odd) {
            background-color: transparent;

            &:hover {
                background-color: $highlight-color;
            }
        }

        th {
            border-top: none;
            border-bottom: 1px solid $body-color;
            color: $body-color;
        }
    }

    & .thead-dark th {
        background-color: transparent !important;
        border-color: transparent !important;
        font-weight: 500;
        border-top: none;
        border-bottom: 1px solid $body-color !important;
        color: $body-color !important;
    }

    .sku-highlight-text {
        font-weight: unset;
    }




    &.specifications, &.simp-performance, &.pim-table, &.SKU-specifications, &.simp-performance-nowidth, &.pim-table-nowidth {

        thead {
            th {
                @include media-breakpoint-down(sm) {
                    font-size: .9rem;
                    word-wrap: normal;
                    white-space: normal;

                }
            }
        }

        tbody {
            tr {
                td {
                    border: none !important;
                    border-bottom: 1px solid #b9b9b9 !important;
                    padding: 5px;
                    word-wrap: normal;

                    @include media-breakpoint-down(sm) {
                        font-size: .8rem;
                    }

                    &:first-child {
                        font-weight: 500;
                    }
                }

                &:nth-of-type(odd) {
                    background-color: transparent !important;
                }
            }
        }
    }

    &.pim-table {
        width: 100%;

        td {
            &:first-child {
                white-space: nowrap;
            }
        }

        th {
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                font-size: .9rem;
            }
        }
    }

    &.specifications {
        min-width: 50%;

        @include media-breakpoint-down(lg) {
            min-width: 60%;
        }

        @include media-breakpoint-down(sm) {
            min-width: 100%;
        }
    }

    &.SKU-specifications {
        width: 100%;
        margin-bottom: 2rem;

        tr {
            td.SKU-table-title {
                border-bottom: 1px solid $body-color;
                padding-top: 2rem;
            }
        }
    }

    &.simp-performance {
        width: 100%;

        tbody {

            tr:first-child {
                td {
                    font-weight: 500;
                    border-bottom: 1px solid $body-color !important;
                }
            }

            td {
                text-align: center;

                &:first-child {
                    white-space: nowrap;
                }
            }
        }
    }
    
    &.simp-performance-nowidth {
        width: auto;

        tbody {

            tr:first-child {
                td {
                    font-weight: 500;
                    border-bottom: 1px solid $body-color !important;
                }
            }

            td {
                text-align: center;

                &:first-child {
                    white-space: nowrap;
                }
            }
        }
    }

    &.pim-table-nowidth {
        width: auto;

        td {
            &:first-child {
                white-space: nowrap;
            }
        }

        th {
            font-weight: 500;

            @include media-breakpoint-down(sm) {
                font-size: .9rem;
            }
        }
    }

    &.downloads-table {

        td {
            border-top: 0px;
            border-bottom: 1px solid #b9b9b9 !important;
        }
    }
}
.water-systems {
    .events-landing-page {
        table {
            &.table {
                &.table-striped tbody tr:nth-of-type(odd) {
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}