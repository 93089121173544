﻿h1 {
    font-family: $font-family-compressed;
    font-size: 2.8rem;
    font-weight: 400;
}

h2 {
    font-family: $font-family-compressed;
    font-weight: 400;
}

h3 {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 1.5rem;
}

h4 {
    font-family: $font-family-condensed;
    font-weight: 400;
    font-size: 1.25rem
}
a {
    color: $secondary-gray;
    text-decoration: underline;
}

a:hover {
    color: $secondary-gray;
    text-decoration: underline;
    transition: none;
    transition: none;
}
