﻿
.main-container {
    &.container {
        background-color: $white;
    }
}

.logo {
    &.footer-image svg {
        fill: $white;
        width: 300px;
        margin-top: 0.5rem;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0rem;
        }
    }

    svg {
        width: 300px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-down(md) {
            width: 180px;
            margin-top: 0px;
            margin-left: 15px;
            margin-bottom: .3rem;
            fill: $white;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 0px;
            width: 100px;
        }
    }
}


.table-hover tbody tr:hover, .table-hover tbody tr:hover a {
    color: $white;
}

.footer {
    background-color: $footer-color;




    .footer-top {
        border-bottom: 1px solid $white;
    }

    .footer-menu {
        font-size: 0.95rem;

        ul {
            li {
                margin-bottom: .5rem;
            }
        }

        .footer-menu-column {
            &:first-child {
                padding-left: 0px;
            }


            &:nth-child(5) {
                @include media-breakpoint-down(md) {
                    padding-left: 0px;
                }
            }

            @include media-breakpoint-down(sm) {
                padding-left: 0px;
            }
        }
    }
}
//// hero banner block overwrite

.hero-banner-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: unset;
    margin-right: -50%;
    -webkit-transform: translate(7.5%, -50%);
    -ms-transform: translate(7.5%, -50%);
    transform: translate(7.5%, -50%);

    @include media-breakpoint-up(md) {
        top: 50%;
    }

    h1 {
        line-height: 2.75rem;
        text-transform: unset !important;
        vertical-align: unset !important;
        text-transform: unset !important;
        text-align: left !important;
        font-size: 2.8rem !important;

        @include media-breakpoint-down(md) {
            font-size: 2rem !important;
        }
    }
}

.hero-banner {
    min-height: 140px;

    @include media-breakpoint-up(md) {
        min-height: 150px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 150px;
    }
}